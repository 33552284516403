<template>
  <div class="login">
    <v-row no-gutters>
      <v-col cols="12" md="7" class="login-bg mobile-hidden">

          <div class="loginText">

            <div class="text-center">
              <img src="https://ik.imagekit.io/soocel/wp/soocel-spotlight_XqMAWdz-X.png" />
            </div>

            <h1 class="mt-3">Welcome to Soocel</h1>
            <p>At Soocel, we understand the power of influencer marketing and how it can help businesses reach new audiences and grow their brand. With our help, you can tap into the influence and reach of top social media influencers to drive engagement and boost sales for your business. </p>

            <p class="mt-6 mb-2"><strong>Our USP</strong></p>

            <ul>
              <li>More than 50K Influencers across social media platforms</li>
              <li>Low CPI for campaigns</li>
            </ul>

            <div class="text-center mt-8 desktop-hidden">
              <v-btn depressed block color="white" @click="showInfo = false">Continue</v-btn>
            </div>

          </div>

          </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <div class="text-center">
          <a
            href="https://www.soocel.com"
            class="d-flex justify-center align-center"
          >
            <img
              src="@/assets/images/soocel-logo.png"
              height="45"
              class="logo"
            />
          </a>
        </div>

        <h2 class="text-center">Reset Password</h2>

        <v-form
          class="login-form"
          v-model="valid"
          lazy-validation
          v-on:submit.prevent="onSubmit"
          ref="setPass"
        >
          <v-alert
            outlined
            type="error"
            v-if="alert"
          >{{ alertMsg }}</v-alert>

          <label class="label">Password</label>
          <v-text-field
            v-model="user.password"
            type="password"
            :rules="setPassRules.password"
            placeholder="Set a password"
            solo
          ></v-text-field>

          <label class="label">Confirm Password</label>
          <v-text-field
            v-model="user.confirmPassword"
            :rules="setPassRules.confirmPassword"
            type="password"
            placeholder="Confirm your password"
            solo
          ></v-text-field>

          <v-btn
            depressed
            dark
            large
            block
            color="primary"
            style="width: 252px;"
            :disabled="loading"
            :loading="loading"
            type="submit"
            @click="resetPass()"
          >Update</v-btn>

        </v-form>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import { resetPassword } from '@/api/user'

export default {
  data () {
    return {
      showInfo: true,
      loading: false,
      valid: true,
      user: {
        token: this.$route.query.token,
        password: null,
        confirmPassword: null
      },
      setPassRules: {
        password: [
          v => !!v || 'Password is required'
        ],
        confirmPassword: [
          v => !!v || 'Password is required',
          v => v === this.user.password || 'Password not match'
        ]
      },
      alertMsg: '',
      alert: false
    }
  },
  methods: {
    async resetPass () {
      if (this.$refs.setPass.validate()) {
        try {
          this.loading = true
          const data = await resetPassword(this.user)
          if (data.success) {
            this.$snackbar({
              message: 'Password reset successfully',
              timeout: 2000
            })
            this.$router.push('/user/login')
          } else {
            this.alertMsg = data.message
            this.loading = false
            this.alert = true
          }
        } catch (error) {
          this.loading = false
          this.alertMsg = 'Something went wrong'
          this.alert = true
        }
      } else {
        console.log('error submit!!')
        return false
      }
    }
  }
}
</script>

<style scoped>
</style>
